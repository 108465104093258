import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Interactive Pitch Deck Template | Uizard"
    category="Web App Templates"
    url="/templates/web-app-templates/interactive-online-pitch-deck/"
    metaDescription="Using Uizard to create your interactive startup pitch deck also means that you can collaborate in real time with your co-founders and your team."
    description="
    h2:An interactive tech startup pitch deck template
    <br/>
    Sending PowerPoint presentations to prospective investors is so last decade. If you are a serious startup founder, you want to make sure that your pitch deck is as compelling as your business idea. Use this app pitch deck template as a base for your pitch deck. The result will be a beautiful and fully interactive web-based tech startup proposition.
    <br/>
    And of course, as a serious founder, you might want to have full control over which investors get access to your brilliant pitch deck. Using Uizard allows you to easily grant viewing access to the few lucky investors that you want on your next funding round.
    <br/>
    h3:A web-based interactive slide deck
    <br/>
    Although Uizard is designed to build mobile app interfaces and website mockups, we've seen a growing number of power users using it to create interactive presentations. That's why we created this tech startup pitch deck template to make it easier for everyone to leverage the power of Uizard to create fully interactive web-based presentations!
    <br/>
    The truth is everyone is tired of receiving ugly PowerPoint or PDF presentations by email…
    <br/>
    Sharing a link to our web-based interactive slide deck is so much easier for everyone. The creator can update slides and correct mistakes even after having shared a link to it, and the receiver doesn't need to install any software to access the presentation. Everybody wins!
    <br/>
    h3:Create a pitch deck online and collaborate in real time
    <br/>
    Using a Uizard <a:https://uizard.io/templates/>UI template</a> to create your pitch deck also means that you can collaborate in real time with your co-founders and your team. Raising capital is hard, and the best pitch decks are created by building on the ideas of the entire founding team. If you've already designed your startup MVP in Uizard, you can easily link your pitch deck to it. There's no better way to make sure that prospective investors have all the information in hand to see how awesome your startup idea is!
    "
    pages={[
      "3 different title slide versions",
      "2 slides to define the problem your startup is trying to solve",
      "A slide to describe your unique solution",
      "A product slide to showcase your value proposition",
      "A slide to describe clearly why your cutting-edge technology will change the world for the better",
      "A slide to illustrate your projections and your path to success",
      "A classical competition slide to brag about your competitive advantage",
      "3 different slide versions to discuss the goals and objectives of your next financing round",
      "A slide to showcase your world-class team",
      "A final slide with your contact information to make sure that all investors have an easy way to get back to you and lead your financing round",
    ]}
    projectCode="QrxaLxXjGZuGvRbqxdbX"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of an interactive online pitch deck: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of an interactive online pitch deck: financial slide"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of an interactive online pitch deck: competition slide"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of an interactive online pitch deck: projection slide"
    img5={data.image5.childImageSharp}
    img5alt="Image summary for the interactive online pitch deck template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/pitch-deck/Pitch-Deck-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/pitch-deck/Pitch-Deck-Funding.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/pitch-deck/Pitch-Deck-Competition.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/pitch-deck/Pitch-Deck-Projections.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/pitch-deck/Pitch-Deck-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
